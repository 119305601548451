import React from "react"
import { Link } from "gatsby"

function ProjectPreviewLink(props) {
  const { linkType } = props
  switch(linkType) {
    case 'internal':
      return (
        <div className="projectActions">
          <div className="separator"></div>

          <Link to={props.link} className="projectAction button large primary">
            <p className="buttonTitle">{props.actionTitle}</p>
          </Link>
        </div>
      )
    case 'dual':
      return (
        <div className="projectActions dualActions">
          <div className="separator"></div>


          <Link to={props.secondaryLink} className="projectAction button large secondary">
            <p className="buttonTitle">{props.secondaryLinkTitle}</p>
          </Link>

          <a href={props.link} target="_blank" rel="noreferrer" className="projectAction button large primary">
            <p className="buttonTitle">{props.actionTitle}</p>
          </a>
        </div>
      )
    case 'hidden':
      return null
    default:
      return (
        <div className="projectActions">
          <div className="separator"></div>

          <a href={props.link} target="_blank" rel="noreferrer" className="projectAction button large primary">
            <p className="buttonTitle">{props.actionTitle}</p>
          </a>
        </div>
      )
  }
}

function ProjectPreviewLinkSpacer(props) {
  const { linkType } = props
  switch(linkType) {
    case 'internal':
      return (
        <div className="projectActionsSpacer">
        </div>
      )
    case 'dual':
      return (
        <div className="projectActionsSpacer dualActions">
        </div>
      )
    case 'hidden':
      return null
    default:
      return (
        <div className="projectActionsSpacer">
        </div>
      )
  }
}

function ProjectBadge(props) {
  const { title } = props
  switch(title) {
    case '':
      return null
    default:
      return (
        <div className="projectBadge">
          <p className="badgeTitle">{props.title}</p>
        </div>
      )
  }
}

function ProjectPreviewSecondaryDescription(props) {
  const { description } = props
  switch(description) {
    case '':
      return null
    default:
      return (
        <div>
          <div className="separator"/>
          <p className="projectDescriptionSecondary">{props.description}</p>
        </div>
      )
  }
}

export default function ProjectPreview(props) {
  return (
    <section className={`projectPreview ${ props.projectClass }`}>
      <div className="projectContent">

        <div className="projectIcon floatingIcon">{props.projectIcon}</div>

        <div className="projectDetails">
          <div className="projectDetailsContent">
            <div className="projectHeader">
              <div className="projectIcon">{props.projectIcon}</div>
              
              <div className="projectHeaderText">
                <h2 className="projectName">{props.projectName}</h2>
                <p className="projectDescription">{props.projectDescription}</p>
              </div>
            </div>

            <ProjectPreviewSecondaryDescription description={props.projectDescriptionSecondary}/>

            <div className="separator"></div>

            <div className="projectRoleDetails">
              <h3 className="projectRole">{props.projectRole}</h3>
              <p className="projectDuration">{props.projectDuration}</p>
            </div>

            <div className="separator"></div>

            <div className="projectAttributes">
              {props.projectAttributes}
            </div>

            <ProjectPreviewLinkSpacer linkType={props.projectLinkType} />
          </div>

          <ProjectPreviewLink
            link={props.projectLink}
            linkType={props.projectLinkType}
            actionTitle={props.projectActionTitle}
            secondaryLink={props.secondaryLink}
            secondaryLinkTitle={props.secondaryLinkTitle}
          />
        </div>

        <div className="projectHeroOverlay"></div>
        <div className="projectHeroGradient"></div>

        <ProjectBadge title={props.badgeTitle} />

        <div className="projectHeroImage">{props.heroImage}</div>
      </div>
    </section>
  )
}


