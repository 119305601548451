import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function HomeIntro(props) {
  return (
    <section className="mercurySection introSection">
      <div className="sectionContent">
        <div className="imageContent">
          <StaticImage
            src="../../images/imageHomePortrait.jpg"
            placeholder="blurred"
            layout="fixed"
            width={240}
            quality="100"
            alt="A 3D portrait of Phil."
          />
        </div>

        <div className="textContent">
          <h1 className="introText">
            Hi, I’m Phil, a Senior Product Designer. I work with entrepreneurs, startups, and established businesses to create engaging and transformative digital products and services.
            <br/><br/>
            My goal is to create the best possible experience at the lowest cost and greatest value possible to your business while maintaining a competitive edge.
          </h1>
        </div>
      </div>
    </section>
  )
}


