import * as React from "react"
import Seo from "../components/seo"
import "../styles/styles.sass"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/header"
import Footer from "../components/footer"
import HomeIntro from "../views/home/homeIntro"
import ProjectAttribute from "../views/project/projectAttribute"
import ProjectPreview from "../views/project/projectPreview"
import HireBanner from "../views/hire/hireBanner"

const IndexPage = () => {
  return (
  <main className="mercuryView homeView">
    <Seo
      title="Phil Amour — Senior Product Designer"
    />
    
    <div className="headerSpacer"/>

    <Header />

    <div className="headerSpacer"/>

    <div className="mercuryContent">
      <HomeIntro />

      <article className="projectsList">
        <ProjectPreview
          projectClass="projectLighthouse"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconLightricks.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Lighthouse icon."
            />
          }
          projectName="Lightricks Design System"
          badgeTitle=""
          projectDescription="Ligtricks, Ltd."
          projectDescriptionSecondary="Cross-organizational and cross-platform design system used across Lightricks apps."
          projectRole="Senior Product Designer"
          projectDuration="2021-PRESENT"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, High-Fidelity Prototyping, Design System Design"
            />

            <ProjectAttribute
              title="Misc"
              detail="Documentation, UI Development"
            />


            <ProjectAttribute
              title="Tools"
              detail="Figma, Origami Studio, Xcode"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, Web"
            />
          </div>}
          projectLinkType="external"
          projectLink="https://lightricks.com"
          projectActionTitle="Learn About Lightricks"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroLighthouse.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A Lightricks design system mockup showcasing different UI components."
            />
          }
        /> {/*End of Project Preview Item*/}

        <div className="sideBySide">

        <ProjectPreview
          projectClass="projectChessUp mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconChessUp.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A ChessUp app icon."
            />
          }
          projectName="ChessUp"
          badgeTitle=""
          projectDescription="Level up your chess game with a connected chessboard."
          projectDescriptionSecondary=""
          projectRole="Lead Mobile Product Designer"
          projectDuration="2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, High-Fidelity Prototyping, Figma"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Onboarding, Social and Multiplayer Experience, Learning, Game Design, Connected Hardware Experience"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, BLE"
            />

            <ProjectAttribute
              title="Business"
              detail="Games, B2C, Hardware"
            />
          </div>}
          projectLink="https://bryghtlabs.com/"
          projectLinkType="external"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroChessUp.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="ChessUp app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectTurnTable mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconTurnTable.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A TurnTable app icon."
            />
          }
          projectName="TurnTable"
          badgeTitle=""
          projectDescription="Get a table at the most exclusive venues and earn NFTs."
          projectDescriptionSecondary=""
          projectRole="Lead Product Designer"
          projectDuration="2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, Prototyping, Figma"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Venue Booking, Marketplace, Reservation Auctions, NFT Reservations, Loyalty Program"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android"
            />

            <ProjectAttribute
              title="Business"
              detail="Venue Booking, B2C, B2B"
            />
          </div>}
          projectLinkType="hidden"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroTurnTable.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="TurnTable app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        </div>

        <ProjectPreview
          projectClass="projectBoostedDS"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconBoosted.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Boosted app icon."
            />
          }
          projectName="Boosted Design System"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary=""
          projectRole="Lead Designer"
          projectDuration="2020-2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="Design System Design, UI Design, Interaction Design, High-Fidelity Prototyping, Figma"
            />

            <ProjectAttribute
              title="Misc"
              detail="Documentation, UI Development"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Advertising"
            />
          </div>}
          projectLinkType="hidden"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroBoostedDS.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A 3d rendering of Boosted design system components."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectLittera"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconLittera.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Littera app icon."
            />
          }
          projectName="Littera Marketing Site"
          badgeTitle=""
          projectDescription="Littera Education Inc."
          projectDescriptionSecondary="High-quality tutoring for every student who needs it."
          projectRole="Developer & Designer"
          projectDuration="2021-PRESENT"
          projectAttributes={<div>
            <ProjectAttribute
              title="Front-End Development"
              detail="React.js, Gatsby.js, CSS/SASS, CMS via Prismic.io"
            />

            <ProjectAttribute
              title="Platform"
              detail="Web"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Custom Layout and Design, SEO Optimization, CMS Integration, Custom Blog via Prismic CMS, Hubspot Forms Integration"
            />

            <ProjectAttribute
              title="Business"
              detail="B2B, SaaS, Education"
            />
          </div>}
          projectLink="https://www.litteraeducation.com/"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroLittera.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Littera website preview."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectCueHit"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconCueHit.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A CueHit app icon."
            />
          }
          projectName="CueHit"
          badgeTitle="Acquired"
          projectDescription="A complete Community Engagement solution for 9-1-1, Law Enforcement and Fire/EMS."
          projectDescriptionSecondary=""
          projectRole="Cofounder & Head of Design"
          projectDuration="2019-2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Figma"
            />

            <ProjectAttribute
              title="Front-End Development"
              detail="React.js, Gatsby.js, CSS/SASS, UI Architecture"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Satisfaction Surveys, Chatbot Experience, Automation Rules, Performance Email Digest, Follow-Up Tasks, Custom Analytics, Video Chat, Officer Wellness Feedback Board, Telehealth, Patient Details and Insurance Insights, Messaging Experience, Triage and Personnel Assignment, Marketing Site, Team & Role Experience and Management (Caller, Patient, Call-Taker, Interpreter, Medic, Doctor, Officer, etc.)"
            />

            <ProjectAttribute
              title="Platforms"
              detail="Web (Desktop), Mobile (Cross-Platform)"
            />

            <ProjectAttribute
              title="Business"
              detail="Public Safety, SaaS, B2B"
            />
          </div>}
          projectLink="https://www.cuehit.com/"
          projectLinkType="dual"
          projectActionTitle="See it Live"
          secondaryLink="/projects/cuehit"
          secondaryLinkTitle="Learn More"
          heroImage={<div className="composedPreview">
            <StaticImage
              src="../images/imageProjectPreviewHeroCueHit.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />

            <div className="floatingCueHitModal">
              <StaticImage
                src="../images/imageProjectPreviewHeroCueHitModal.png"
                placeholder="blurred"
                layout="fixed"
                width={560}
                quality={100}
                alt="Halligan app mockups."
              />
            </div>
          </div>}
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectWebPayments"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconFT2.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A FaceTune 2 app icon."
            />
          }
          projectName="Web Subscriptions"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary="Moving all mobile apps from native subscriptions to web experience - covering the entire funnel from ads to acquisitions and payments while optimizing conversions, revenue, and customer experience."
          projectRole="Lead Product Designer"
          projectDuration="2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing"
            />

            <ProjectAttribute
              title="Misc"
              detail="Market Research, Data Analysis"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Dynamic Microsites, Sign Up Experience, Payment & Subscription Experience"
            />

            <ProjectAttribute
              title="Platform"
              detail="Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Marketing, Payments"
            />
          </div>}
          projectLinkType="hidden"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroWebPayments.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Web payments mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectLightHub"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconLightricks.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Lightricks app icon."
            />
          }
          projectName="Link in Bio"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary=""
          projectRole="Lead Product Designer"
          projectDuration="2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, Figma"
            />

            <ProjectAttribute
              title="Front-End Development"
              detail="CSS/SASS"
            />

            <ProjectAttribute
              title="Misc"
              detail="Market Research, Data Analysis"
            />

            <ProjectAttribute
              title="Platform"
              detail="Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Creator Services"
            />
          </div>}
          projectLink="https://ltx.bio/"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroLightHub.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Link in bio app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectBoostedWeb"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconBoosted.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Boosted app icon."
            />
          }
          projectName="Boosted Web"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary="Create professionally designed marketing videos."
          projectRole="Lead Product Designer"
          projectDuration="2020-2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, Figma"
            />

            <ProjectAttribute
              title="Platform"
              detail="Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Advertising"
            />
          </div>}
          projectLink="https://boosted.lightricks.com/"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroBoostedWeb.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Boosted web mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <div className="sideBySide">
        <ProjectPreview
          projectClass="projectBoostedMobile mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconBoosted.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Boosted app icon."
            />
          }
          projectName="Boosted Mobile"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary="Create professionally designed marketing videos."
          projectRole="Senior Product Designer"
          projectDuration="2019-2021"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Figma"
            />

            <ProjectAttribute
              title="Misc"
              detail="Mentoring"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Advertising"
            />
          </div>}
          projectLink="https://apps.apple.com/us/app/boosted-ad-maker-by-lightricks/id1435480281"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroBoostedMobile.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Boosted app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectVega mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconVega.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A PosterBoost app icon."
            />
          }
          projectName="PosterBoost"
          badgeTitle=""
          projectDescription="Lightricks, Ltd."
          projectDescriptionSecondary="Create engaging animated posters that wow your audience."
          projectRole="Lead Designer"
          projectDuration="2018-2019"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Design System Design"
            />

            <ProjectAttribute
              title="Tools"
              detail="Sketch, Xcode, Origami Studio"
            />

            <ProjectAttribute
              title="Misc"
              detail="Market Research, Data Analysis, Mentoring, Hiring, Design Leadership, Presenting"
            />

            <ProjectAttribute
              title="Platform"
              detail="iOS"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Advertising"
            />
          </div>}
          projectLink="https://apps.apple.com/us/app/posterboost-poster-maker/id1468085151"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroVega.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="PosterBoost app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        </div>

        <ProjectPreview
          projectClass="projectPetLine"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconPetLine.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A PetLine app icon."
            />
          }
          projectName="PetLine"
          badgeTitle=""
          projectDescription="Better vet relationships start here."
          projectDescriptionSecondary=""
          projectRole="Cofounder & Head of Design"
          projectDuration="2019"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Figma"
            />

            <ProjectAttribute
              title="Front-End Development"
              detail="React.js, Gatsby.js, CSS/SASS"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Messaging, Triage, Payment Plans, Client Workflows, Video Chats, Telemedicine, Marketing Site"
            />

            <ProjectAttribute
              title="Platform"
              detail="Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Veterinary, B2B, SaaS"
            />
          </div>}
          projectLink="https://petline.netlify.app/"
          projectLinkType="external"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroPetLine.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="PetLine app mockup."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectRapidReceipt"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconRapidReceipt.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A RapidReceipt app icon."
            />
          }
          projectName="RapidReceipt"
          badgeTitle=""
          projectDescription="All your expenses. Organized."
          projectDescriptionSecondary=""
          projectRole="Lead Product Designer"
          projectDuration="2019-2020"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, High-Fidelity Prototyping, Sketch, Origami Studio"
            />

            <ProjectAttribute
              title="Front-End Development"
              detail="React.js, CSS/SAAS"
            />

            <ProjectAttribute
              title="Misc"
              detail="Product Management, Project Management"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Onboarding, Account Management, ML-Powered Capture Experience, Organization, Data Entry, Dashboards and Insights"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Expense Tracking, B2C, B2B"
            />
          </div>}
          projectLink="https://rapidreceipt.io/"
          projectActionTitle="See it Live"
          heroImage={<div className="composedPreview">
            <StaticImage
              src="../images/imageProjectPreviewHeroRapidReceipt.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Rapid Receipt app mockups."
            />

            <div className="floatingMobile">
              <StaticImage
                src="../images/imageProjectPreviewHeroRapidReceiptMobile.png"
                placeholder="blurred"
                layout="fixed"
                width={300}
                quality={100}
                alt="Rapid Receipt app mockups."
              />
            </div>
          </div>}
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectHalligan"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconHalligan.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Haligan app icon."
            />
          }
          projectName="Halligan"
          badgeTitle="Acquired"
          projectDescription="The most powerful solution for emergency responders."
          projectDescriptionSecondary=""
          projectRole="Head of Design"
          projectDuration="2017-2020"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Design System Design, Sketch"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, Web"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Inspections of trucks, tools, medical supplies, controlled substances (DEA Compliant), and other items logged in inventory records. Powerful auditing experience. Efficient task mangement. Simplified reporting and analytics."
            />

            <ProjectAttribute
              title="Business"
              detail="Public Safety, B2B, SaaS"
            />
          </div>}
          projectLink="https://halligan.io/"
          projectActionTitle="See it Live"
          heroImage={<div className="composedPreview">
            <StaticImage
              src="../images/imageProjectPreviewHeroHalligan.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Halligan app mockups."
            />

            <div className="floatingMobile">
              <StaticImage
                src="../images/imageProjectPreviewHeroHalliganMobile.png"
                placeholder="blurred"
                layout="fixed"
                width={300}
                quality={100}
                alt="Halligan app mockups."
              />
            </div>
          </div>}
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectSnupps"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconSnupps.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Snupps app icon."
            />
          }
          projectName="Snupps"
          badgeTitle="Case Study"
          projectDescription="Organize, Connect, Buy & Sell."
          projectDescriptionSecondary=""
          projectRole="Lead Product Designer"
          projectDuration="2013-2018"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Design System Design, Sketch"
            />

            <ProjectAttribute
              title="Front-End Development"
              detail="React.js, Gatsby.js, CSS/LESS, Swift"
            />

            <ProjectAttribute
              title="Misc"
              detail="Product Management, Data Analysis, Mentoring, Design Leadership"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Onboarding, Item Capture, Item Organization, Social Profiles, Feed Experience, Behavior Tracking, Analysis, and Advanced Recommendations, Interest Groups, Advanced Discovery Features, User-Created Groups, Buying & Selling Experience, eBay Experience, Cross-Platform Design System, Marketing Site (Development), Web App Design and Development"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android, Web"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media, Inventory Management, Marketplace"
            />
          </div>}
          projectLink="/projects/snupps"
          projectLinkType="internal"
          projectActionTitle="Read the Case Study"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroSnupps.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Snupps app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <div className="sideBySide">
        <ProjectPreview
          projectClass="projectSnuppsMarketplace mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconSnuppsMarketplace.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Snupps Marketplace app icon."
            />
          }
          projectName="Snupps Marketplace"
          badgeTitle=""
          projectDescription="Snupps, Inc."
          projectDescriptionSecondary="Buy and sell items locally or shipped."
          projectRole="Lead Product Designer"
          projectDuration="2018-2019"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Sketch"
            />

            <ProjectAttribute
              title="Misc"
              detail="Product Management, Data Analysis, Mentoring, Design Leadership"
            />

            <ProjectAttribute
              title="Solutions"
              detail="End-to-End Buying and Selling Experience, Trust Design, Payments, Withdrawals, Identity Verification and Trust Score"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android"
            />

            <ProjectAttribute
              title="Business"
              detail="Marketplace, B2C, B2B"
            />
          </div>}
          projectLinkType="hidden"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroSnuppsMarketplace.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Snupps Marketplace app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectTusk mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconTusk.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Tusk app icon."
            />
          }
          projectName="Tusk"
          badgeTitle=""
          projectDescription="Beautiful Mastodon client for iPhone."
          projectDescriptionSecondary=""
          projectRole="Co-Founder & Designer"
          projectDuration="2020"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, Interaction Design, High-Fidelity Prototyping, Design System Design, Sketch, Figma"
            />

            <ProjectAttribute
              title="Platform"
              detail="iOS"
            />

            <ProjectAttribute
              title="Business"
              detail="Social Media"
            />
          </div>}
          projectLinkType="hidden"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroTusk.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Tusk app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        </div>

        <div className="sideBySide">
        <ProjectPreview
          projectClass="projectTogether mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconTogether.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Boosted app icon."
            />
          }
          projectName="Together"
          badgeTitle=""
          projectDescription="Venture Christian Church, Los Gatos, CA"
          projectDescriptionSecondary="Deeply connect with others around topics that matter."
          projectRole="Lead Product Designer"
          projectDuration="2016"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Sketch"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Group Learning, Interactive Chatbot-Powered Learning, Conversations, Event Scheduling and Management (Virtual & In-Person), Group Management, Onboarding, Account Management"
            />

            <ProjectAttribute
              title="Platform"
              detail="iOS"
            />

            <ProjectAttribute
              title="Business"
              detail="Group Learning, Social Media, B2C"
            />
          </div>}
          projectLink="https://dribbble.com/philipamour/projects/523415-Together"
          projectActionTitle="See More on Dribbble"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroTogether.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Together app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectSwiftDine mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconSwiftDine.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A SwiftDine app icon."
            />
          }
          projectName="SwiftDine"
          badgeTitle=""
          projectDescription="Order, pay and dine with the people you love."
          projectDescriptionSecondary=""
          projectRole="Lead Designer"
          projectDuration="2016"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Sketch"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Onboarding, Account Management, Venue Discovery, Booking Experience, Dining Experience, Payments, Order Tracking, Ratings & Reviews, Social Features"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android"
            />

            <ProjectAttribute
              title="Business"
              detail="Dining, Booking, B2C, B2B"
            />
          </div>}
          projectLink="https://dribbble.com/philipamour/projects/510043-SwiftDine"
          projectActionTitle="See More on Dribbble"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroSwiftDine.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A SwiftDine app mockup showcasing a list of venues."
            />
          }
        /> {/*End of Project Preview Item*/}

        </div>

        <div className="sideBySide">
        <ProjectPreview
          projectClass="projectWait mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconWait.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Wait app icon."
            />
          }
          projectName="Wait"
          badgeTitle=""
          projectDescription="See where to go to based on the wait times around you."
          projectDescriptionSecondary=""
          projectRole="Lead Product Designer"
          projectDuration="2015"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Sketch"
            />

            <ProjectAttribute
              title="Solutions"
              detail="Venue Discovery, Crowd Sourced Information Gathering, Onboarding, Account Management"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, watchOS"
            />

            <ProjectAttribute
              title="Business"
              detail="Dining, B2C"
            />
          </div>}
          projectLink="https://dribbble.com/philipamour/projects/508582-Wait"
          projectActionTitle="See More on Dribbble"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroWait.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Wait app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        <ProjectPreview
          projectClass="projectChess mobileOnly"
          projectIcon={
            <StaticImage
              src="../images/imageProjectPreviewIconChess.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="A Chess app icon."
            />
          }
          projectName="Chess.com"
          badgeTitle=""
          projectDescription="The #1 Chess site!"
          projectDescriptionSecondary=""
          projectRole="Lead Mobile Product Designer"
          projectDuration="2013"
          projectAttributes={<div>
            <ProjectAttribute
              title="Design"
              detail="Product Design, UI Design, Photoshop"
            />

            <ProjectAttribute
              title="Platforms"
              detail="iOS, Android"
            />

            <ProjectAttribute
              title="Business"
              detail="Games, B2C"
            />
          </div>}
          projectLink="https://www.chess.com"
          projectActionTitle="See it Live"
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroChess.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="Chess app mockups."
            />
          }
        /> {/*End of Project Preview Item*/}

        </div>

        <ProjectPreview
          projectClass="projectClassix"
          projectIcon=""
          projectName="Classix macOS Icon Theme"
          badgeTitle=""
          projectDescription="A fun little personal project from 2020. Designed a set of iOS-inspired icons for macOS. Just in time time for macOS Big Sur."
          projectDescriptionSecondary=""
          projectRole=""
          projectDuration=""
          projectAttributes=""
          projectLink=""
          projectLinkType="hidden"
          projectActionTitle=""
          heroImage={
            <StaticImage
              src="../images/imageProjectPreviewHeroClassix.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A grid of macOS inspired icons."
            />
          }
        /> {/*End of Project Preview Item*/}

        <HireBanner
          bannerTitle=""
          bannerSubtitle=""
        />

      </article>

    </div>
    
    <Footer />
  </main>
  )
}

export default IndexPage
